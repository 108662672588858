import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";

import { useNavigate } from "react-router-dom";
import api from "../../core/api";

import "./searchbar.css";

export default function Searchbar() {
  const [items, setItems] = useState([]);
  const [itemSearched, setItemSearched] = useState(
    localStorage.getItem("azienda") || ""
  );
  const [shouldLoad, setShouldLoad] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const CustomOption = (props) => {
    if (props.data.isLoadMore) {
      return (
        <div onClick={props.selectProps.loadMoreItems}>Carica altri...</div>
      );
    }
    return <components.Option {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg
          width="30px"
          height="30px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
            stroke="#000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </components.DropdownIndicator>
    );
  };
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <p>Nessun cliente trovato</p>
      </components.NoOptionsMessage>
    );
  };

  const customStyles = {
    indicatorSeparator: () => null, // this will remove the divider
    DropdownIndicator: () => null, // this will remove the dropdown arrow

    control: (provided, state) => ({
      ...provided,
      cursor: "text",
      borderRadius: "50px",
      height: "60px",
      padding: "0 10px",
      boxSizing: "border-box",
      borderColor: "transparent",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      height: "60px",
      boxSizing: "border-box",
    }),
  };
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const res = await api.post("/aziende", {
          search: inputValue,
          CodiceReferente: localStorage.getItem(
            "EuroluxIncassoCodiceReferente"
          ),
        });
        const newItems = res.data.map((item) => ({
          value: item.CodCliente, // Use CodCliente as the value for unique identification
          label: `${item.NomeAzienda} #${item.CodCliente}`, // Combine NomeAzienda and CodCliente
        }));

        // Create a unique list and sort it
        const uniqueItems = Array.from(
          new Set([...items, ...newItems].map(JSON.stringify))
        )
          .map(JSON.parse)
          .sort((a, b) => a.label.localeCompare(b.label));

        setItems(uniqueItems);

        // Set the default value if not present in localStorage
        if (!localStorage.getItem("CodCliente") && uniqueItems.length > 0) {
          const firstItem = uniqueItems[0];
          console.log(firstItem);
          localStorage.setItem("CodCliente", firstItem.value);
          setItemSearched(firstItem.label); // Use label for display
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchItems();
  }, [inputValue]);

  return (
    <Select
      onInputChange={setInputValue}
      onMenuOpen={() => setShouldLoad(true)}
      onKeyDown={(e) => {
        if (e.key == "Enter") {
          console.log("Enter");
          setShouldLoad(true);
        }
      }}
      options={[...items]}
      value={{ value: itemSearched, label: itemSearched }}
      onChange={(selectedOption) => {
        localStorage.setItem("CodCliente", selectedOption.value);
        localStorage.setItem("azienda", selectedOption.label);
        setItemSearched(selectedOption.label);
      }}
      components={{
        Option: CustomOption,
        DropdownIndicator: DropdownIndicator, // remove the dropdown arrow
        IndicatorSeparator: null, // remove the divider
        NoOptionsMessage: NoOptionsMessage,
      }}
      styles={customStyles}
    />
  );
}
