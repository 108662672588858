import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Loader from "../../components/Loader/Loader";

export default function Esci() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("EuroluxIncassoToken");
    localStorage.removeItem("EuroluxIncassoCodiceReferente");
    localStorage.removeItem("CodCliente");
    localStorage.removeItem("EuroluxIncassoUtente");

    navigate("/");
  }, []);
  return <Loader></Loader>;
}
