import React, { useState, useEffect } from "react";

import { Navigate } from "react-router-dom";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import DropZoneModal from "../../components/Modal/DropZoneModal";
import ModalAddCodiceReferente from "../../components/Modal/ModalAddCodiceReferente";

import api from "../../core/api";

import "./admin.css";

export default function Admin() {
  const [isModalCaricaListinoOpen, setIsModalCaricaListinoOpen] =
    useState(false);
  const [isModalAddCodiceReferente, setIsModalAddCodiceReferente] =
    useState(false);
  const [richieste, setRichieste] = useState([]);
  const [utenti, setUtenti] = useState([]);
  const [utentiError, setUtentiError] = useState(false);
  const [allowed, setAllowed] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("EuroluxIncassoToken");
    // Replace '/adminCheck' with your actual endpoint
    api
      .get("/adminCheck", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then(() => setAllowed(true))
      .catch(() => setAllowed(false));
    if (allowed) {
      api
        .get("/getRichieste", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          setRichieste(res.data);
        });

      api
        .get("/getUtenti", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          setUtenti(res.data);
        })
        .catch((err) => {
          setUtentiError(true);
        });
    }
  }, [allowed]);
  if (allowed === null) {
    return <div>Controllo di sicurezza admin...</div>;
  }

  if (!allowed) {
    return <Navigate to={"/Dashboard"} />;
  }

  const handleRequest = (status, iduser) => {
    const token = localStorage.getItem("EuroluxIncassoToken");
    api
      .post(
        "/handleRequest",
        {
          status,
          iduser,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        window.location.reload();
      });
  };

  return (
    <div className="admin">
      <Navbar></Navbar>
      <div className="adminBg">
        {isModalCaricaListinoOpen && (
          <DropZoneModal
            setIsModalCaricaListinoOpen={() => setIsModalCaricaListinoOpen()}
            isModalCaricaListinoOpen={isModalCaricaListinoOpen}
          ></DropZoneModal>
        )}
        {isModalAddCodiceReferente != false ? (
          <ModalAddCodiceReferente
            iduser={isModalAddCodiceReferente.iduser}
            nome={isModalAddCodiceReferente.nome}
            CodiceReferente={isModalAddCodiceReferente.CodiceReferente}
            setIsModalAddCodiceReferente={setIsModalAddCodiceReferente}
          ></ModalAddCodiceReferente>
        ) : null}
        <h1>Admin Dashboard</h1>
        <div
          className="downloadButton"
          onClick={() => setIsModalCaricaListinoOpen(!isModalCaricaListinoOpen)}
        >
          Carica Listino
        </div>
        <div className="richieste">
          <h3>Richieste di ammissione</h3>
          {richieste.length > 0 ? (
            richieste.map((richiesta) => (
              <div className="richiestaCard">
                <div className="richiestaCardTitle">
                  {richiesta.nome && <p>{richiesta.nome}</p>}
                  {richiesta.mail && <p>{richiesta.mail}</p>}
                  {richiesta.codice && <p>{richiesta.codice}</p>}
                </div>
                <div className="richiesteButtons">
                  <div
                    className="accettaRichiestaButton"
                    onClick={() => handleRequest(2, richiesta.iduser)}
                  >
                    Accetta come agente
                  </div>
                  <div
                    className="accettaRichiestaButton"
                    onClick={() => handleRequest(1, richiesta.iduser)}
                  >
                    Accetta come cliente
                  </div>
                  <div
                    className="rifiutaRichiestaButton"
                    onClick={() => handleRequest(0, richiesta.iduser)}
                  >
                    Rifiuta
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h4>Nessuna richiesta</h4>
          )}
        </div>
        <div className="Utenti">
          <h3>Utenti</h3>
          {utenti.length > 0 ? (
            utenti.map((utente) => (
              <div
                className="richiestaCard"
                onClick={() =>
                  setIsModalAddCodiceReferente({
                    iduser: utente.iduser,
                    nome: utente.nome,
                    CodiceReferente: utente.CodiceReferente,
                  })
                }
              >
                <div className="richiestaCardTitle">
                  {utente.nome && <p>{utente.nome}</p>}
                  {utente.mail && <p>{utente.codice}</p>}
                </div>
                <div>
                  {utente.CodiceReferente && (
                    <p>Codice Referente: {utente.CodiceReferente}</p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <h4>Caricamento utenti...</h4>
          )}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
