import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./reg.css";
import api from "../../core/api";

import arrowRight from "../../assets/svg/arrowRight.svg";

import eyeShow from "../../assets/svg/eyeShow.svg";
import eyeHide from "../../assets/svg/eyeHide.svg";

export default function SignIn() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [awaitCode, setAwaitCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleReg = () => {
    const nome = document.getElementById("nome").value;
    const codice = document.getElementById("codice").value;
    const password = document.getElementById("password").value;
    const città = document.getElementById("città").value;
    const CAP = document.getElementById("CAP").value;
    const indirizzo = document.getElementById("indirizzo").value;
    const codFiscale = document.getElementById("codFiscale").value;
    const mail = document.getElementById("mail").value;
    const piva = document.getElementById("piva").value;

    api
      .post("/signin", {
        nome,
        codice,
        password,
        città,
        CAP,
        indirizzo,
        codFiscale,
        mail,
        piva,
      })
      .then((res) => {
        setAwaitCode(true);
      })
      .catch((error) => {
        console.log("An error occurred:", error);
        setError(true);
      });
  };
  return (
    <div className="reg">
      <Navbar></Navbar>
      <div className="regBg">
        {awaitCode ? (
          <h1>Richiesta inoltrata, verrai confermato/a a breve</h1>
        ) : (
          <>
            <div>
              <h1 style={{ textAlign: "center" }}>Crea un account</h1>
              <p style={{ textAlign: "center" }}>
                Tranquillo/a, potrai cambiare queste informazioni in seguito
              </p>
              <div className="info">
                <div className="infoPersonali">
                  <h3>Informazioni Personali</h3>

                  <div className="inputButton">
                    <input
                      type="nome"
                      name="nome"
                      id="nome"
                      required
                      placeholder="Nome"
                    />
                  </div>
                  <div className="inputButton">
                    <input
                      type="codFiscale"
                      name="codFiscale"
                      id="codFiscale"
                      required
                      placeholder="Codice Fiscale"
                    />
                  </div>
                  <div className="inputButton">
                    <input
                      type="piva"
                      name="piva"
                      id="piva"
                      placeholder="Partita IVA"
                    />
                  </div>
                  <div className="inputButton">
                    <input
                      type="indirizzo"
                      name="indirizzo"
                      id="indirizzo"
                      required
                      placeholder="indirizzo"
                    />
                  </div>
                  <div className="inputButton">
                    <input
                      type="CAP"
                      name="CAP"
                      id="CAP"
                      required
                      placeholder="CAP"
                    />
                  </div>
                  <div className="inputButton">
                    <input
                      type="città"
                      name="città"
                      id="città"
                      required
                      placeholder="Città"
                    />
                  </div>
                </div>
                <div className="infoAccount">
                  <h3>Account</h3>
                  <div className="inputButton">
                    <input
                      type="email"
                      name="mail"
                      id="mail"
                      required
                      placeholder="Email"
                    />
                  </div>
                  <div className="inputButton">
                    <input
                      type="text"
                      name="codice"
                      id="codice"
                      required
                      placeholder="Username"
                    />
                  </div>
                  <div className="inputButton">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      required
                      placeholder="Password"
                    />
                    <img
                      src={showPassword ? eyeHide : eyeShow}
                      alt="Show Password"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                </div>
              </div>
              <div className="mainButton" onClick={handleReg}>
                <div>Procedi</div>
                <img
                  draggable="false"
                  width={"40px"}
                  height={"30px"}
                  src={arrowRight}
                  alt="Procedi"
                />
              </div>
              <p
                className="switchRegLog"
                onClick={() => {
                  navigate("/Login");
                  window.scrollTo(0, 0);
                }}
              >
                Ho già un account
              </p>
            </div>
            <div className="errorText">{error ? "Campi non validi" : null}</div>
          </>
        )}
      </div>

      <Footer></Footer>
    </div>
  );
}
