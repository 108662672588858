import React from "react";

import api from "../../core/api";
import goBack from "../../assets/svg/goBack.svg";

export default function ModalAddCodiceReferente({
  CodiceReferente,
  iduser,
  nome,
  setIsModalAddCodiceReferente,
}) {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const handleAddCodiceReferente = () => {
    const CodiceReferente = document.getElementById("CodiceReferente").value;
    api
      .post("/addCodiceReferente", {
        iduser,
        CodiceReferente,
      })
      .then((res) => {
        setIsModalAddCodiceReferente(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log("An error occurred:", error);
      });
  };

  return (
    <div
      className="modalBg"
      onClick={() => setIsModalAddCodiceReferente(false)}
    >
      <div className="modalAddCodiceReferente" onClick={stopPropagation}>
        <div className="tornaIndietro">
          <h2 onClick={() => setIsModalAddCodiceReferente(false)}>
            <img src={goBack} alt="Torna indietro" draggable="false" />
            {nome}
          </h2>
        </div>
        <div className="inputButton">
          <input
            type="number"
            name="CodiceReferente"
            id="CodiceReferente"
            placeholder={CodiceReferente ? CodiceReferente : "Codice Referente"}
          />
        </div>
        <div className="mainButton" onClick={handleAddCodiceReferente}>
          <div>Aggiungi</div>
        </div>
      </div>
    </div>
  );
}
