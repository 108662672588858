import React from "react";
import "./imageSlider.css";
import playIcon from "../../assets/svg/playIcon.svg";
import pauseIcon from "../../assets/svg/pauseIcon.svg";

export default function CurrentImageIndicator({
  currentImageIndex,
  totalImages,
  togglePlayPause,
  isPlaying,
  className,
}) {
  return (
    <div className={className}>
      <img
        alt="play/pause"
        onClick={() => togglePlayPause()}
        src={isPlaying ? pauseIcon : playIcon}
        style={{ marginRight: "10px" }}
        draggable="false"
      ></img>
      {Array.from({ length: totalImages }, (_, i) => i).map((_, i) => (
        <span
          key={i}
          //   deve settare l'index della foto cliccata e mostrare quella
          //   onClick={togglePlayPause}
          style={{
            cursor: "pointer",
            padding: "5px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "50%",
              backgroundColor: i === currentImageIndex ? "white" : "#b3b3b3",
            }}
          ></div>
        </span>
      ))}
    </div>
  );
}
