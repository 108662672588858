import React from "react";
import "./loader.css";

export default function Loader() {
  return (
    <div className="loaderBalls">
      <div className="loaderBall1"></div>
      <div className="loaderBall2"></div>
      <div className="loaderBall3"></div>
    </div>
  );
}
