import React, { useEffect } from "react";
import "./chisiamoCard.css";

export default function ChisiamoCard({
  image,
  title,
  lunVen,
  sab,
  setIsMoreInfoModalOpen,
}) {
  return (
    <div className="ChisiamoCard" onClick={setIsMoreInfoModalOpen}>
      <img src={image} alt={title} draggable="false" />
      <div className="ChisiamoCardInfo">
        <h3>{title}</h3>
        <p>Lun - Ven: {lunVen}</p>
        {sab ? <p>Sab {sab} </p> : null}
      </div>
    </div>
  );
}
