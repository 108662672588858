/* eslint-disable max-len */
import axios from "axios";

const API_URL = "https://eurolux-incasso-back-eff9a6a9bc74.herokuapp.com";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
