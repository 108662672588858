import React from "react";

import goBack from "../../assets/svg/goBack.svg";

import "./moreInfoModal.css";

export default function MoreInfoModal({
  logos,
  isMoreInfoModalOpen,
  setIsMoreInfoModalOpen,
}) {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modalBg" onClick={() => setIsMoreInfoModalOpen(0)}>
      <div className="moreInfoModal" onClick={stopPropagation}>
        <h2 onClick={() => setIsMoreInfoModalOpen(0)}>
          <img src={goBack} alt="Torna indietro" draggable="false" />
          {isMoreInfoModalOpen}
        </h2>
        <div className="moreInfoModalInfos">
          {logos.map((info) => (
            <div className="moreInfoModalInfo">
              <img src={info.logo} alt="logo" draggable="false" />
              <p>{info.nome}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
