import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";

import ProtectedRoute from "./routes/ProtectedRoute"; // Import the ProtectedRoute
import AdminRoute from "./pages/Admin/Admin";

import Home from "./pages/Home/Home";
import Catalogo from "./pages/Catalogo/Catalogo";
import Chisiamo from "./pages/Chisiamo/Chisiamo";
import Agenti from "./pages/Agenti/Agenti";
import Assistenza from "./pages/Assistenza/Assistenza";
import Login from "./pages/Login/Login";
import Reg from "./pages/Reg/Reg";
import Dashboard from "./pages/Dashboard/Dashboard";
import Carrello from "./pages/Carrello/Carrello";
import Esci from "./pages/Esci/Esci";
import Cronologia from "./pages/Cronologia/Cronologia";
import Admin from "./pages/Admin/Admin";
import Profilo from "./pages/Profilo/Profilo";
import Cliente from "./pages/Cliente/Cliente";

import { CartProvider } from "./components/CartContext/CartContext";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/Chisiamo" element={<Chisiamo />} />

          <Route path="/Agenti" element={<Agenti />} />
          <Route path="/Assistenza" element={<Assistenza />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Registration" element={<Reg />} />

          <Route
            path="/Ordini"
            element={<ProtectedRoute component={() => <Cronologia />} />}
          />
          <Route
            path="/Dashboard"
            element={<ProtectedRoute component={() => <Dashboard />} />}
          />
          <Route
            path="/Catalogo"
            element={<ProtectedRoute component={() => <Catalogo />} />}
          />
          <Route
            path="/Carrello"
            element={<ProtectedRoute component={() => <Carrello />} />}
          />
          <Route
            path="/Admin"
            element={<ProtectedRoute component={() => <Admin />} />}
          />
          <Route
            path="/Cliente"
            element={<ProtectedRoute component={() => <Cliente />} />}
          />
          <Route
            path="/Profilo"
            element={<ProtectedRoute component={() => <Profilo />} />}
          />
          <Route path="/Esci" element={<Esci />} />

          {/* <ProtectedRoute path="/Dashboard" element={<Dashboard />} /> */}
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
