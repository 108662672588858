import React, { useState, useEffect, useRef } from "react";
import "./imageSlider.css";
import arrowLeft from "../../assets/svg/arrowLeft.svg";
import arrowRight from "../../assets/svg/arrowRight.svg";

export default function ProductsSlider({
  imageGroups,
  currentGroupIndex,
  setCurrentGroupIndex,
  isProductsPlaying,
}) {
  const timer = useRef(null);
  console.log(imageGroups[currentGroupIndex].title, "tt");
  const title = imageGroups[currentGroupIndex].title;
  const desc = imageGroups[currentGroupIndex].desc;
  const images = imageGroups[currentGroupIndex].products; // Select the appropriate group

  useEffect(() => {
    if (isProductsPlaying) {
      timer.current = setInterval(() => {
        setCurrentGroupIndex(
          (prevIndex) => (prevIndex + 1) % imageGroups.length
        );
      }, 10000);
    } else {
      clearInterval(timer.current);
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [isProductsPlaying]);

  const nextGroup = () => {
    setCurrentGroupIndex((prevIndex) => (prevIndex + 1) % imageGroups.length);
  };

  const prevGroup = () => {
    setCurrentGroupIndex(
      (prevIndex) => (prevIndex - 1 + imageGroups.length) % imageGroups.length
    );
  };

  return (
    <div className="productImageSlider">
      <img
        draggable="false"
        src={arrowLeft}
        alt="Gruppo precedente"
        className="arrowLeft"
        onClick={prevGroup}
      ></img>
      <div className="sliderProductsInfo">
        <h3>{title}</h3>
        <p>{desc}</p>
        <div className="sliderProductsImages">
          {images.map((image, index) => (
            <a href={image.link}>
              <img
                draggable="false"
                key={index}
                src={image.image}
                alt={`slider-product-${index}`}
                className="sliderProductsImage"
              />
            </a>
          ))}
        </div>
      </div>
      <img
        draggable="false"
        src={arrowRight}
        alt="Gruppo successivo"
        className="arrowRight"
        onClick={nextGroup}
      ></img>
    </div>
  );
}
