import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./login.css";

import arrowRight from "../../assets/svg/arrowRight.svg";
import eyeShow from "../../assets/svg/eyeShow.svg";
import eyeHide from "../../assets/svg/eyeHide.svg";

import api from "../../core/api";

export default function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [awaitCode, setAwaitCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    const codice = document.getElementById("codice").value;
    const password = document.getElementById("password").value;
    api
      .post(
        "/login",
        {
          codice,
          password,
        },
        {
          headers: {
            Authorization: `${
              localStorage.getItem("EuroluxIncassoToken")
                ? localStorage.getItem("EuroluxIncassoToken")
                : ""
            }`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data != "Il tuo account non è ancora stato approvato") {
          localStorage.setItem("EuroluxIncassoToken", res.data.token);
          localStorage.setItem("EuroluxIncassoUtente", res.data.utente);
          localStorage.setItem(
            "EuroluxIncassoCodiceReferente",
            res.data.ClientType
          );
          navigate("/Dashboard");
          window.scrollTo(0, 0);
        } else if (res.data == "Il tuo account non è ancora stato approvato") {
          setAwaitCode(true);
        }
      })
      .catch((error) => {
        console.log("An error occurred:", error);
        setError(true);
      });
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("EuroluxIncassoToken");
  //   if (token) {
  //     api
  //       .get("/verifyToken", { headers: { Authorization: token } })
  //       .then((res) => {
  //         if (res.data.success == "dsds") {
  //           navigate("/dashboard");
  //           window.scrollTo(0, 0);
  //         } else {
  //           localStorage.removeItem("EuroluxIncassoToken");
  //         }
  //       });
  //   }
  // }, []);

  return (
    <div className="login">
      <Navbar></Navbar>
      <div className="loginBg">
        {awaitCode ? (
          <h1>Richiesta inoltrata, verrai confermato/a a breve</h1>
        ) : (
          <>
            <h1>Accesso Riservato</h1>
            <div className="inputButton">
              <input
                type="text"
                name="codice"
                id="codice"
                placeholder="Username"
              />
            </div>
            <div className="inputButton">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Password"
              />
              <img
                src={showPassword ? eyeHide : eyeShow}
                alt="Show Password"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <div className="mainButton" onClick={handleLogin}>
              <div>Accedi</div>
              <img
                draggable="false"
                width={"40px"}
                height={"30px"}
                src={arrowRight}
                alt="Accedi"
              />
            </div>
            <div className="errorText">
              {error ? "Email o password errate" : null}
            </div>
            <p
              className="switchRegLog"
              onClick={() => {
                navigate("/Registration");
                window.scrollTo(0, 0);
              }}
            >
              Crea un account
            </p>
          </>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
}
