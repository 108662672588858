import React, { useState, useEffect } from "react";

import { useCart } from "../../components/CartContext/CartContext";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Searchbar from "../../components/Searchbar/Searchbar";
import DropZoneModal from "../../components/Modal/DropZoneModal";
import { jwtDecode } from "jwt-decode";

import searchIcon from "../../assets/svg/searchIcon.svg";
import add from "../../assets/svg/add.svg";
import minus from "../../assets/svg/minus.svg";

import Select from "react-select";
import api from "../../core/api";

import "./dashboard.css";
import Loader from "../../components/Loader/Loader";
export default function Dashboard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const { addToCart, removeOneFromCart } = useCart();

  // const [error, setError] = useState(false);
  const [codArt, setCodArt] = useState("");
  const [description, setDescription] = useState("");
  const fornitori = [
    { value: "", label: "Scegli fornitore..." },
    { value: "AEG BUILT - IN", label: "AEG BUILT - IN" },
    { value: "AEG LIBERA INSTALLAZIONE", label: "AEG LIBERA INSTALLAZIONE" },
    { value: "APELL SPA", label: "APELL SPA" },
    { value: "ARGO RUBINETTERIE SRL", label: "ARGO RUBINETTERIE SRL" },
    { value: "BEKO ITALY SRL", label: "BEKO ITALY SRL" },
    { value: "CANDY B.I.", label: "CANDY B.I." },
    { value: "CANDY F.S.", label: "CANDY F.S." },
    { value: "CIAC - ELLECI", label: "CIAC - ELLECI" },
    { value: "DIVIEMME SRL", label: "DIVIEMME SRL" },
    { value: "DRAMIS  S.R.L.", label: "DRAMIS  S.R.L." },
    { value: "ELBA ITALY SPA", label: "ELBA ITALY SPA" },
    { value: "ELECTROLUX F.S.", label: "ELECTROLUX F.S." },
    {
      value: "ELECTROLUX FLOOR CARE ITALIA SPA",
      label: "ELECTROLUX FLOOR CARE ITALIA SPA",
    },
    { value: "FABER  S.P.A.", label: "FABER  S.P.A." },
    {
      value: "FOX SPA DI RENZO BOMPANI & C.",
      label: "FOX SPA DI RENZO BOMPANI & C.",
    },
    {
      value: "GOLLINUCCI SRL   A SOCIO UNICO",
      label: "GOLLINUCCI SRL   A SOCIO UNICO",
    },
    {
      value: "GRF DOMESTIC HOME APPLIANCE SRL",
      label: "GRF DOMESTIC HOME APPLIANCE SRL",
    },
    {
      value: "HAIER EUROPE TRADING SRL-ITALY BRAN",
      label: "HAIER EUROPE TRADING SRL-ITALY BRAN",
    },
    { value: "HISENSE ITALIA SRL", label: "HISENSE ITALIA SRL" },
    { value: "HOOVER F.S.", label: "HOOVER F.S." },
    { value: "I & D SRL - BLANCO", label: "I & D SRL - BLANCO" },
    { value: "INDEL B SPA", label: "INDEL B SPA" },
    { value: "JOINLUX SRL", label: "JOINLUX SRL" },
    { value: "LAVENOX SRL", label: "LAVENOX SRL" },
    { value: "LG ELECTRONICS ITALIA SPA", label: "LG ELECTRONICS ITALIA SPA" },
    { value: "MBM SRL", label: "MBM SRL" },
    { value: "MENEGHETTI SPA", label: "MENEGHETTI SPA" },
    { value: "METALINEA S.R.L.", label: "METALINEA S.R.L." },
    { value: "MOBIL JOLLY 2012 SRL", label: "MOBIL JOLLY 2012 SRL" },
    { value: "OPES  S.R.L. UNIPERSONALE", label: "OPES  S.R.L. UNIPERSONALE" },
    { value: "PYRAMID ITALIA SRL", label: "PYRAMID ITALIA SRL" },
    { value: "PYRAMIS METALLOURGIA A.E.", label: "PYRAMIS METALLOURGIA A.E." },
    {
      value: "REX B.I. -ELECTROLUX APPLIANCES SPA",
      label: "REX B.I. -ELECTROLUX APPLIANCES SPA",
    },
    { value: "ROBERTO CROLLA SRL", label: "ROBERTO CROLLA SRL" },
    {
      value: "S.F.L. SOCIETE' FABRIC. LUMIN. SAS",
      label: "S.F.L. SOCIETE' FABRIC. LUMIN. SAS",
    },
    { value: "SANGIORGIO - IT WASH", label: "SANGIORGIO - IT WASH" },
    {
      value: "SAS FRIO ENTERPRISE-CLIMADIFF",
      label: "SAS FRIO ENTERPRISE-CLIMADIFF",
    },
    { value: "SATEP  SRL   ASS. TECNICA", label: "SATEP  SRL   ASS. TECNICA" },
    { value: "SCHAUB LORENZ ITALIA SRL", label: "SCHAUB LORENZ ITALIA SRL" },
    { value: "SIDERPLAST SRL", label: "SIDERPLAST SRL" },
    {
      value: "SPECTRUM BRANDS ITALIA SRL",
      label: "SPECTRUM BRANDS ITALIA SRL",
    },
    { value: "SPS ELLECI S.p.A.", label: "SPS ELLECI S.p.A." },
  ];
  const [fornitoreSelected, setFornitoreSelected] = useState("");
  const [isClient, setIsClient] = useState(true);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "text",
      borderRadius: "60px",
      height: "60px",
      padding: "0 10px",
      borderColor: "transparent",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      height: "60px",
    }),
    container: (provided) => ({
      ...provided,
    }),
  };
  useEffect(() => {
    const decodedToken = localStorage.getItem("EuroluxIncassoToken")
      ? jwtDecode(localStorage.getItem("EuroluxIncassoToken"))
      : null;

    if (decodedToken?.isClient == 1) {
      setIsClient(true);
    } else {
      setIsClient(false);
    }
  }, []);

  function countOccurrences(arr, val) {
    if (!arr) {
      return 0;
    }

    return arr.reduce((acc, item) => {
      if (item === val) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }
  const handleDownload = () => {
    setIsLoading(true);

    api
      .get("/download", { responseType: "text" })
      .then((response) => {
        setIsLoading(false);
        const base64Response = response.data; // Assumendo che il server invii una stringa Base64 pulita
        const binaryString = window.atob(base64Response);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes.buffer], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "listino.pdf");
        document.body.appendChild(link);
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log("Errore nel download del file", error);
      });
  };

  const handleSearch = () => {
    api
      .post("/searchProducts", {
        codArt,
        description,
        fornitore: fornitoreSelected,
      })
      .then((res) => {
        setProducts(res.data);
        console.log(res.data);
        if (res.data) {
          console.log(res.data);
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        // setError(true);
      });
  };

  return (
    <div className="dashboard">
      <Navbar></Navbar>
      <div className="dashboardBg">
        {isClient ? (
          <>
            <h2>Benvenuto {localStorage.getItem("EuroluxIncassoUtente")}</h2>
            <div className="downloadButton" onClick={() => handleDownload()}>
              Scarica listino
            </div>
          </>
        ) : (
          <>
            <h1>Effettua un ordine</h1>
            <div className="downloadButton" onClick={() => handleDownload()}>
              Scarica listino
            </div>
            <div className="selectDiv">
              <h2>Cliente</h2>
              <Searchbar></Searchbar>

              <div
                className="smButton"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/Cliente");
                  window.scrollTo(0, 0);
                }}
              >
                Informazioni Fatturato
              </div>
            </div>
            <br /> <br />
            <h3>Info prodotto</h3>
            <div className="selectDiv">
              <h2>Fornitore</h2>
              <Select
                placeholder="Scegli fornitore..."
                options={fornitori}
                onChange={(selectedOption) => {
                  setFornitoreSelected(selectedOption.value);
                }}
                styles={customStyles}
              />
            </div>
            <div className="inputBottom">
              <div className="inputButton">
                <input
                  type="text"
                  name="codArt"
                  id="codArt"
                  placeholder="Codice Articolo"
                  value={codArt}
                  onChange={(e) => setCodArt(e.target.value)}
                />
              </div>
              <div className="inputButton">
                <input
                  type="text"
                  name="descrizione"
                  id="descrizione"
                  placeholder="Descrizione"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="mainButton" onClick={handleSearch}>
                <div>Cerca</div>
                <img
                  draggable="false"
                  width={"40px"}
                  height={"30px"}
                  src={searchIcon}
                  alt="Accedi"
                />
              </div>
            </div>
            <div className="resultTab">
              {products.map((product) => (
                <div className="resultProduct">
                  <div className="resultProductHeader">CodArt</div>
                  <div className="resultProductHeader">Description</div>
                  <div className="resultProductHeader">Fornitore</div>
                  {product.CodArt}
                  <div className="resultProductDescription">
                    {product.Description}
                  </div>
                  <div> {product.Fornitore}</div>
                  {localStorage.getItem("EuroluxIncassoClient") ? (
                    <div className="productBottomRight">
                      <p className="miniHeader">Disponibilità</p>
                      <div>
                        {product.GiacenzeNapoli == null ? (
                          ""
                        ) : (
                          <p>Napoli: {product.GiacenzeNapoli}</p>
                        )}
                      </div>
                      <div>
                        {product.GiacenzeBari == null ? (
                          ""
                        ) : (
                          <p>Bari: {product.GiacenzeBari}</p>
                        )}
                      </div>
                      <div>
                        {product.GiacenzeLazio == null ? (
                          ""
                        ) : (
                          <p>Lazio: {product.GiacenzeLazio}</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="productBottomRight">
                      <div
                        className="addProduct"
                        onClick={() => removeOneFromCart(product.CodArt)}
                      >
                        <img src={minus} alt="Rimuovi dal carrello" />
                      </div>
                      <div className="selectedProductCount">
                        {countOccurrences(
                          JSON.parse(localStorage.getItem("cart")),
                          product.CodArt
                        )}
                      </div>
                      <div
                        className="addProduct"
                        onClick={() => addToCart(product.CodArt)}
                      >
                        <img src={add} alt="Aggiungi al carrello" />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div
              className="dashboardButton"
              onClick={() => navigate("/Carrello")}
            >
              Mostra carrello
            </div>
          </>
        )}
      </div>

      <Footer></Footer>
    </div>
  );
}
