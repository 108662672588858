import React, { useState, useEffect } from "react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import "./cronologia.css";

import api from "../../core/api";

export default function Cronologia() {
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    api
      .post("/getOrders", {
        utente: localStorage.getItem("EuroluxIncassoUtente"),
      })
      .then((res) => {
        console.log("orders", res.data);
        setOrders(res.data);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, []);

  return (
    <div className="cronologia">
      <Navbar></Navbar>
      <div className="cronologiaBg">
        <h1>I miei ordini</h1>

        {orders.length > 0 ? (
          orders.map((order) => (
            <div className="orderCard">
              <div>
                <h3>Data: </h3>
                {new Date(order.datetime).toLocaleString("it-IT")}
              </div>
              <div>
                <h3>Cliente: </h3>
                {order.to}
              </div>
              <div>
                <h3>Ordine:</h3>
                {order.orderData}
              </div>
            </div>
          ))
        ) : (
          <h2>Non hai ancora effettuato ordini.</h2>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
}
