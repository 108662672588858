import React, { useEffect, useState } from "react";
import api from "../../core/api";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";

import goBack from "../../assets/svg/goBack.svg";

export default function Cliente() {
  const [loader, setLoader] = useState(false);
  const [cliente, setCliente] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    api
      .get("/getClienteData", {
        headers: {
          Authorization: `${localStorage.getItem("EuroluxIncassoToken")}`,
          "Content-Type": "application/json",
          CodCliente: `${localStorage.getItem("CodCliente")}`,
        },
      })
      .then((res) => {
        setLoader(false);
        setCliente(res.data.result[0]);
        console.log(res.data.result[0]);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, []);

  return (
    <div className="reg">
      <Navbar></Navbar>
      <div className="regBg">
        <>
          {loader ? <Loader></Loader> : null}
          <div className="info">
            <div className="infoCliente">
              <h2 onClick={() => navigate("/Dashboard")}>
                <img src={goBack} alt="Torna indietro" draggable="false" />{" "}
                Torna indietro
              </h2>
              <h1 className="inputHeader">{cliente.NomeAzienda}</h1>
              <p>
                {cliente.CodiceReferente
                  ? `Codice Referente: ${cliente.CodiceReferente}`
                  : "Nessun Referente"}
              </p>
              <br /> <br />
              <p>
                {cliente.Via}, {cliente.Citta} ({cliente.Provincia}),{" "}
                {cliente.CAP}
              </p>
              <br />
              <br />
              <h3>Residuo Fido</h3>
              {cliente.FidoResiduo || cliente.Fido ? (
                <p>
                  {cliente.FidoResiduo}€ / {cliente.Fido}€
                </p>
              ) : (
                <p>Non ha un fido</p>
              )}
              <br />
              <br />
              <h3>Fatture</h3>
              <p>
                <b>Fatturato: </b>
                {cliente.Fatturato
                  ? cliente.Fatturato + "€"
                  : "Nessun fatturato"}
              </p>
              <br />
              <p>
                <b>Fatturato anno precedente: </b>
                {cliente.FatturatoAnnoPrecedente
                  ? cliente.FatturatoAnnoPrecedente + "€"
                  : "Nessun fatturato anno precedente"}
              </p>
              <br />
              <p>
                <b>Fatture da pagare: </b>
                {cliente.FattureDaPagare
                  ? cliente.FattureDaPagare + "€"
                  : "Nessuna fattura da pagare"}
              </p>
              <br />
              <h3>Riba</h3>
              <p>
                {cliente.RibaScadenzaImporto
                  ? cliente.RibaScadenzaImporto.split(" ").reduce(
                      (acc, element, index, array) => {
                        // Process pairs only for even indices
                        if (index % 2 === 0) {
                          const date = element;
                          const price = array[index + 1];

                          // Check if both date and price exist
                          if (date && price) {
                            acc.push(
                              <span key={index}>
                                <b>Scadenza: </b> {date} - {price}€<br />
                              </span>
                            );
                          }
                        }
                        return acc;
                      },
                      []
                    )
                  : "Nessuna riba"}
              </p>
              <h3>Insoluti</h3>
              <p>
                <b>Importo: </b>
                {cliente.Insoluti ? cliente.Insoluti + "€" : "Nessun insoluto"}
              </p>
            </div>
          </div>
        </>
      </div>

      <Footer></Footer>
    </div>
  );
}
