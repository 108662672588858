import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./profilo.css";
import api from "../../core/api";

import tickIcon from "../../assets/svg/tickIcon.svg";
import Loader from "../../components/Loader/Loader";

export default function SignIn() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [awaitCode, setAwaitCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [info, setInfo] = useState({});

  const handleReg = () => {
    const nome = document.getElementById("nome").value
      ? document.getElementById("nome").value
      : info.nome;
    const città = document.getElementById("città").value
      ? document.getElementById("città").value
      : info.città;
    const CAP = document.getElementById("CAP").value
      ? document.getElementById("CAP").value
      : info.CAP;
    const indirizzo = document.getElementById("indirizzo").value
      ? document.getElementById("indirizzo").value
      : info.indirizzo;
    const codFiscale = document.getElementById("codFiscale").value
      ? document.getElementById("codFiscale").value
      : info.codFiscale;
    const piva = document.getElementById("piva").value
      ? document.getElementById("piva").value
      : info.piva;

    api
      .post(
        "/modifyAccountData",
        {
          nome,
          città,
          CAP,
          indirizzo,
          codFiscale,
          piva,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("EuroluxIncassoToken")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoader(false);
      })
      .catch((error) => {
        console.log("An error occurred:", error);
        setError(true);
      });
  };

  useEffect(() => {
    api
      .get("/getAccountData", {
        headers: {
          Authorization: `${localStorage.getItem("EuroluxIncassoToken")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setInfo(res.data.result[0]);
      });
  }, []);

  return (
    <div className="reg">
      <Navbar></Navbar>
      <div className="regBg">
        <>
          {loader ? <Loader></Loader> : null}
          <h1>Modifica informazioni Personali</h1>
          <div className="info">
            <div className="infoPersonali">
              <div className="inputHeader">Nome</div>
              <div className="inputButton">
                <input
                  type="nome"
                  name="nome"
                  id="nome"
                  required
                  placeholder={info.nome}
                />
              </div>

              <div className="inputHeader">Codice Fiscale</div>
              <div className="inputButton">
                <input
                  type="codFiscale"
                  name="codFiscale"
                  id="codFiscale"
                  required
                  placeholder={info.codFiscale}
                />
              </div>
              <div className="inputHeader">Partita IVA</div>
              <div className="inputButton">
                <input
                  type="piva"
                  name="piva"
                  id="piva"
                  required
                  placeholder={info.piva}
                />
              </div>
              <div className="inputHeader">Indirizzo</div>
              <div className="inputButton">
                <input
                  type="indirizzo"
                  name="indirizzo"
                  id="indirizzo"
                  required
                  placeholder={info.indirizzo}
                />
              </div>

              <div className="inputHeader">CAP</div>
              <div className="inputButton">
                <input
                  type="CAP"
                  name="CAP"
                  id="CAP"
                  required
                  placeholder={info.CAP}
                />
              </div>

              <div className="inputHeader">Città</div>
              <div className="inputButton">
                <input
                  type="città"
                  name="città"
                  id="città"
                  required
                  placeholder={info.città}
                />
              </div>
            </div>
          </div>
          <div className="mainButton" onClick={handleReg}>
            <div>Conferma Modifiche</div>
            <img src={tickIcon} alt="Conferma Modifiche" />
          </div>
          <p
            className="switchRegLog"
            onClick={() => {
              navigate("/Dashboard");
              window.scrollTo(0, 0);
            }}
          >
            Annulla Modifiche
          </p>

          <div className="errorText">{error ? "Campi non validi" : null}</div>
        </>
      </div>

      <Footer></Footer>
    </div>
  );
}
