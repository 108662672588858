import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { jwtDecode } from "jwt-decode";

import { Squash as Hamburger } from "hamburger-react";
import logoEurolux from "../../assets/svg/LogoEurolux.svg";
import avatar from "../../assets/svg/avatar.svg";

import "./navbar.css";
export default function Navbar() {
  const [accountMenu, setAccountMenu] = useState(false);
  const [logged, setLogged] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isClient, setIsClient] = useState(true);

  const pages = [
    { label: "Home", path: "/" },
    { label: "Chi siamo", path: "/Chisiamo" },
    { label: "Agenti", path: "/Agenti" },
    { label: "Centro Assistenza", path: "/Assistenza" },
  ];

  const accountNotLogged = [
    { label: "Login", path: "/Login" },
    { label: "Registrazione", path: "/Registration" },
  ];
  const accountLogged = [
    { label: "Dashboard", path: "/Dashboard" },
    isClient ? null : { label: "I miei ordini", path: "/Ordini" },
    isClient ? null : { label: "Carrello", path: "/Carrello" },
    isClient ? null : { label: "Admin", path: "/Admin" },
    { label: "Catalogo", path: "/Catalogo" },
    { label: "Profilo", path: "/Profilo" },
    { label: "Esci", path: "/Esci" },
  ].filter(Boolean);

  const currentPath = window.location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    const decodedToken = localStorage.getItem("EuroluxIncassoToken")
      ? jwtDecode(localStorage.getItem("EuroluxIncassoToken"))
      : null;
    if (decodedToken?.isClient == 1) {
      setIsClient(true);
    } else {
      setIsClient(false);
    }
    if (localStorage.getItem("EuroluxIncassoToken")) {
      setLogged(true);
    } else {
      setLogged(false);
    }

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="navbar">
        {windowWidth > 800 ? (
          <>
            <div className="logo">
              <img src={logoEurolux} alt="Eurolux" draggable="false" />
            </div>
            <div className="navbarCenter">
              <h2 className="navbarTitle">EUROLUX INCASSO</h2>
              <div className="navbarOptions">
                {pages.map((page) => (
                  <>
                    <div className="navbarOption">
                      <div
                        style={{
                          cursor: "pointer",
                          color:
                            page.path == window.location.pathname
                              ? "#005192"
                              : "#000000",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                          position: "relative",
                          fontSize: "18px",
                        }}
                        onClick={() => {
                          navigate(page.path);
                          window.scrollTo(0, 0);
                        }}
                      >
                        {page.label}
                      </div>
                    </div>
                    {page.path != "/Assistenza" && (
                      <div className="navbarDivider"></div>
                    )}
                  </>
                ))}
              </div>
            </div>
            <div className="navbarLogoGhost">
              <img
                draggable="false"
                src={avatar}
                alt="Accesso privato"
                onClick={() => {
                  setAccountMenu(!accountMenu);
                }}
              ></img>
              {accountMenu && (
                <div className="accountMenu">
                  {logged ? (
                    <>
                      {accountLogged.map((item) => (
                        <div
                          onClick={() => {
                            navigate(`${item.path}`);
                            window.scrollTo(0, 0);
                          }}
                          className="accountMenuOption"
                        >
                          {item.label === "Esci" ? (
                            <div className="logoutButton">{item.label}</div>
                          ) : (
                            item.label
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {accountNotLogged.map((item) => (
                        <div
                          onClick={() => {
                            navigate(`${item.path}`);
                            window.scrollTo(0, 0);
                          }}
                          className="accountMenuOption"
                        >
                          {item.label}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="navbarCenterMobile">
            <div>
              <h3>EUROLUX INCASSO</h3>
            </div>

            <Hamburger
              toggled={isOpen}
              toggle={setIsOpen}
              rounded
              onToggle={() => {
                setShowMenu(!showMenu);
              }}
              size={20}
            />
          </div>
        )}
      </div>

      {showMenu && windowWidth < 780 && (
        <div
          style={{
            marginTop: "6vh",
            position: "fixed",
            top: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            padding: "80px 20px 20px 20px",
            boxSizing: "border-box",
            zIndex: "990",
            overflowY: "scroll",
          }}
        >
          {pages.map((page) => (
            <div
              style={{
                color: currentPath === page.path ? "#4169E1" : "#333",
                cursor: "pointer",
                fontWeight: currentPath === page.path ? "bold" : "normal",
                padding: "20px",
              }}
              onClick={() => {
                navigate(page.path);
                window.scrollTo(0, 0);
              }}
            >
              {page.label}
            </div>
          ))}
          <h2>Area Riservata</h2>

          <div>
            {logged ? (
              <>
                {accountLogged.map((item) => (
                  <div
                    onClick={() => {
                      navigate(`${item.path}`);
                      window.scrollTo(0, 0);
                    }}
                    style={{
                      color: currentPath === item.path ? "#4169E1" : "#333",
                      cursor: "pointer",
                      fontWeight: currentPath === item.path ? "bold" : "normal",
                      padding: "20px",
                      textAlign: "center",
                    }}
                  >
                    {item.label === "Esci" ? (
                      <div className="logoutButton">{item.label}</div>
                    ) : (
                      item.label
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>
                {accountNotLogged.map((item) => (
                  <div
                    onClick={() => {
                      navigate(`${item.path}`);
                      window.scrollTo(0, 0);
                    }}
                    style={{
                      color: currentPath === item.path ? "#4169E1" : "#333",
                      cursor: "pointer",
                      fontWeight: currentPath === item.path ? "bold" : "normal",
                      padding: "20px",
                      textAlign: "center",
                    }}
                  >
                    {item.label}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
