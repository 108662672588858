import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import "./agenti.css";
import avatar from "../../assets/svg/avatar.svg";

export default function Agenti() {
  const agenti = [
    {
      nome: "DE MARTINO SALVATORE",
      zona: "Provincia di Napoli, Avellino",
      num: "338 7635276",
      email: "salvatoredemartino78@libero.it",
    },
    {
      nome: "LEPRE GIANLUCA",
      zona: "Province di Napoli, isole e Caserta",
      num: "338 2284507",
      email: "gianlucalepre75@gmail.com",
    },
    {
      nome: "MOCCIA GIOVANNI",
      zona: "Caserta, Salerno",
      num: "335 6627149",
      email: "mocciaiser@gmail.com",
    },
    {
      nome: "MOCCIA RICCARDO",
      zona: "Benevento, provincia Caserta e Basilicata",
      num: "333 1123465",
      email: "riccardomoccia@virgilio.it",
    },
    {
      nome: "GALEAZZI MIRCO",
      zona: "Foggia, Andria, Barletta, Trani e Province",
      num: "335 5423652",
      email: "galeazzimirco@libero.it",
    },
    {
      nome: "CENTRONE GIULIANA",
      zona: "Provincia di Bari e Taranto Lecce e Province, Brindisi, Matera e Province",
      num: "348 8035103",
      email: "giulianacentrone@libero.it",
    },
    {
      nome: "GARBATI SIMONE",
      zona: "Regione Lazio",
      num: "338 6118750",
      email: "simonegarbati@hotmail.com",
    },
    {
      nome: "PERCUOCO MARCO",
      zona: "AEG B.I. per regioni Campania e Basilicata",
      num: "348 2315366",
      email: "percuocom@gmail.com",
    },
  ];

  return (
    <div>
      <Navbar></Navbar>
      <div className="agenti">
        <h1>Agenti di zona</h1>
        <div className="agentiCards">
          {agenti.map((agente) => (
            <div className="agenteCard">
              <div className="agenteCardTop">
                <h3>{agente.nome}</h3>
                <p>{agente.zona}</p>
              </div>
              <img
                draggable="false"
                width={"100%"}
                height={"33%"}
                src={avatar}
                alt={`${agente.nome}`}
              />
              <div className="agenteCardBottom">
                <p>{agente.num}</p>
                <p>{agente.email}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
