import React, { useState, useEffect } from "react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./carrello.css";
import { useNavigate } from "react-router-dom";
import Searchbar from "../../components/Searchbar/Searchbar";

import add from "../../assets/svg/add.svg";
import minus from "../../assets/svg/minus.svg";

import { useCart } from "../../components/CartContext/CartContext";
import api from "../../core/api";

function Carrello() {
  const navigate = useNavigate();
  const [cartProducts, setCartProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const { removeFromCart, removeOneFromCart, addToCart } = useCart();

  let cart = JSON.parse(localStorage.getItem("cart"));
  let utente = localStorage.getItem("EuroluxIncassoUtente");
  let clientName = localStorage.getItem("azienda");

  useEffect(() => {
    console.log("UseEffectCall");
    api
      .post("/getCartItems", {
        cart,
        clientName,
      })
      .then((res) => {
        const total = res.data.reduce(
          (acc, product) => acc + product.PrezzoImpon * product.orderedQuantity,
          0
        );
        console.log("apidata", res.data);
        setTotalPrice(total);
        setCartProducts(res.data);
      })
      .catch((error) => {
        console.error("Carrello vuoto");
      });
  }, [cart?.length]);

  function countOccurrences(arr, val) {
    if (!arr) {
      return 0;
    }

    return arr.reduce((acc, item) => {
      if (item === val) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }

  const handleSendEmail = () => {
    api
      .post("/newOrder", {
        orderData: cartProducts
          .map((product) => product.orderedQuantity + "x " + product.CodArt)
          .join(", "),
        to: clientName,
        utente,
      })
      .then((res) => {
        localStorage.removeItem("cart");
        console.log(res);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
    // api
    //   .post("/sendEmail", {
    //     to: "sant22619@gmail.com",
    //     subject: "Hello",
    //     text: "Hello world",
    //   })
    //   .then((res) => {
    //     console.log("Email inviata:", res.data);
    //   })
    //   .catch((error) => {
    //     console.error("Email non inviata:", error);
    //   });
  };
  return (
    <div className="carrello">
      <Navbar></Navbar>
      <div className="carrelloBg">
        <h1>Carrello</h1>
        {cart?.length == 0 || cart == null ? (
          <>
            <h2>Il carrello è vuoto.</h2>
            <div
              className="tornaDashButton"
              onClick={() => navigate("/Dashboard")}
            >
              Torna alla dashboard
            </div>
          </>
        ) : (
          <>
            {console.log(cart)}
            <div className="selectDiv">
              <h2>Cliente</h2>
              <Searchbar></Searchbar>
            </div>
            {cartProducts.map((product) => (
              <div className="product">
                <div className="productInfo">
                  <h3>CodArt. {product.CodArt}</h3>

                  <p>{product.Description}</p>
                  <h4>
                    <b>€{product.PrezzoImpon}</b> (Iva inclusa)
                  </h4>

                  <div>
                    <div className="modifyProductsQuantity">
                      <div
                        className="addProduct"
                        onClick={() => removeOneFromCart(product.CodArt)}
                      >
                        <img src={minus} alt="Rimuovi dal carrello" />
                      </div>
                      <div className="selectedProductCount">
                        {countOccurrences(cart, product.CodArt)}
                      </div>
                      <div
                        className="addProduct"
                        onClick={() => addToCart(product.CodArt)}
                      >
                        <img src={add} alt="Aggiungi al carrello" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="removeIcon"
                    onClick={() => removeFromCart(product.CodArt)}
                  >
                    Rimuovi
                  </div>
                </div>
              </div>
            ))}
            <div className="totalPrice">
              Prezzo Totale: <h3>€{totalPrice}</h3>
            </div>
            <div className="carrelloButtons">
              <div
                className="tornaDashButton"
                onClick={() => navigate("/Dashboard")}
              >
                Torna alla dashboard
              </div>
              <a
                onClick={handleSendEmail}
                href={`mailto:storace15@gmail.com?subject=Ordine Eurolux Incasso&body=Nuovo ordine da ${utente}  per ${clientName}, Articoli: ${cartProducts.map(
                  (product) =>
                    " " + product.orderedQuantity + "x " + product.CodArt
                )}.`}
              >
                <div className="procediButton">Procedi all'ordine</div>
              </a>
            </div>
          </>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Carrello;
