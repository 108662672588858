import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import CandyLogo from "../../assets/SliderProductsImages/Incasso/CandyLogo.svg";
import Electrolux from "../../assets/SliderProductsImages/Incasso/Electrolux.png";
import AEG from "../../assets/SliderProductsImages/Incasso/AEG.png";

import "./assistenza.css";

export default function Assistenza() {
  const generali = [
    { num: "199.123.123", loghi: [Electrolux, AEG] },

    { num: "199.100.100", loghi: [CandyLogo] },
  ];

  const zone = [
    { zona: "Avellino", num: "0825683169" },
    { zona: "Bari", num: "0805461329" },
    { zona: "Benevento", num: "082443588" },
    { zona: "Brindisi", num: "0831521935" },
    { zona: "Foggia", num: "0881746336" },
    { zona: "Ischia", num: "081907393" },
    { zona: "Potenza", num: "0972721681" },
    { zona: "Matera", num: "0835335905" },
    { zona: "Napoli", num: "0817590161" },
    { zona: "Salerno", num: "089753289" },
    { zona: "Taranto", num: "099337654" },
  ];
  return (
    <div>
      <Navbar></Navbar>

      <div className="aziendaAssistenza">
        <div className="aziendaAssistenzaHeader">
          <h1>Centri Assistenza</h1>
          <h3>Numeri unici</h3>
        </div>
        <div className="aziendeAssistenzaCards">
          {generali.map((card) => (
            <div className="aziendaAssistenzaCard">
              <div className="aziendaAssistenzaImages">
                {card.loghi.map((logo) => (
                  <>
                    <img
                      draggable="false"
                      className="aziendaAssistenzaImage"
                      src={logo}
                      alt={logo}
                    />
                  </>
                ))}
              </div>
              <a className="assistenzaNumber" href={`tel:${card.num}`}>
                {card.num}
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="zoneAssistenza">
        <h2>Zone specifiche</h2>
        {zone.map((zona) => (
          <div className="zoneAssistenzaCard">
            <h3>{zona.zona}</h3> <h4>{zona.num}</h4>
          </div>
        ))}
      </div>
      <Footer></Footer>
    </div>
  );
}
