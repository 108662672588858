import React, { useRef } from "react";
import api from "../../core/api";

import pdfIcon from "../../assets/svg/pdfIcon.svg";

import goBack from "../../assets/svg/goBack.svg";

function DropZoneModal({
  isModalCaricaListinoOpen,
  setIsModalCaricaListinoOpen,
}) {
  const fileInputRef = useRef(null);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(",")[1]; // Extract the base64 string from the data URL
      formData.append("fileBase64", base64String);

      api
        .post("/uploadListino", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setIsModalCaricaListinoOpen(false);
          console.log("File caricato con successo", response);
        })
        .catch((error) => {
          console.log("Errore nel caricamento del file", error);
        });
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (e) => {
    uploadFile(e.target.files[0]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    uploadFile(e.dataTransfer.files[0]);
  };

  return (
    <div className="modalBg" onClick={() => setIsModalCaricaListinoOpen(false)}>
      <div className="moreInfoModal" onClick={stopPropagation}>
        <h2 onClick={() => setIsModalCaricaListinoOpen(false)}>
          <img src={goBack} alt="Torna indietro" draggable="false" />
          Torna indietro
        </h2>
        <div
          onClick={handleFileInputClick}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          className="dropZone"
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <img src={pdfIcon} alt="Scegli file" />
          <h4>Sposta un file qui o clicca per caricarlo</h4>
        </div>
      </div>
    </div>
  );
}

export default DropZoneModal;
