import React from "react";

import "./catalogo.css";

import Navbar from "../../components/Navbar/Navbar";
import catalogoImg from "../../assets/Catalogo/Catalogo2024.png";

export default function Catalogo() {
  return (
    <div>
      <Navbar></Navbar>
      <div className="catalogo">
        <h1>Catalogo</h1>
        <div className="catalogoCard">
          <img src={catalogoImg} alt="Catalogo 2024" />
          <div className="catalogoWrapper">
            <h3>Catalogo Eurolux Incasso 2024</h3>
            <a
              href="https://eurolux.s3.eu-central-1.amazonaws.com/Catalogo2024.pdf"
              download="Catalogo-Eurolux-Incasso-2024.pdf"
            >
              Scarica il PDF
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
